import { ReactNode } from 'react'

/**
 * This component renders a cds icon.
 *
 * @param {string} icon defined name in https://cds.circutor.com/?path=/docs/fundations-iconography--docs.
 * @param {string} cdsColorToken defined color in https://cds.circutor.com/?path=/docs/fundations-colors--docs.
 * @param {string} extraClass to add in rendered element.
 * @param {string} onClick handler to exec when the user clicks on the icon.
 * @param {string} ariaLabelledBy to pass to aria-labelledby to icon.
 * @returns {ReactNode} A React element that renders a icon.
 */
export const Icon = ({
  icon,
  extraClass = '',
  cdsColorToken,
  onClick,
  ariaLabelledBy = '',
}: {
  icon: string
  ariaLabelledBy?: string
  extraClass?: any
  cdsColorToken?: string
  onClick?: () => void
}): ReactNode => {
  const styleObject = cdsColorToken ? { color: `var(${cdsColorToken})` } : {}
  return (
    <span
      aria-labelledby={ariaLabelledBy}
      style={styleObject}
      className={`${extraClass} ${icon}`}
      onClick={() => onClick && onClick()}
    />
  )
}
